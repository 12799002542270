<template>
  <!-- <sdPageHeader title="Select Game" /> -->
  <div class="game-container">
    <div class="admin-banner-container">
      <div class="bannet-title">Choose your game</div>
      <div class="bannet-note">for your gamification campaign...</div>
    </div>
    <Main>
      <a-row :gutter="25">
        <a-col
          :xxl="6" :lg="6" :sm="6" :xs="24"
          v-for="game of games"
          :key="game.encrypt_id"
        >
          <GameCardStyle @click="handleGameSelect(game)" :style="{ marginBottom: '25px' }">
            <img
              :src="game.image"
              :alt="game.name"
            />
            <p class="game-name">{{game.name}}</p>
            <div v-if="selectedGame == game.encrypt_id" class="selected-game">
              <i class="fa fa-check-circle select-icon-class"/>
            </div>
          </GameCardStyle>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import { Main } from "../../styled";
import { GameCardStyle } from "./style";
import { API } from "@/services/api";
import Notification from '@/services/Notification';
export default {
  name: "SelectGame",
  computed: {
    initial: function() {
      return this.$store.getters.initial;
    }
  },
  components: {
    Main,
    GameCardStyle,
  },
  data() {
    return {
      games: [],
      selectedGame: null,
      currentPage: 1,
      perPage: 12,
      fetching: true,
    };
  },
  methods: {
    async fetchGames() {
      try {
        const params = {
          limit: this.perPage,
          page: this.currentPage,
        };
        const resData = await API.getGames({params});
        const { data = {}, status, message = "" } = resData;
        if (status) {
          const { games } = data?.data ?? {};
          this.games = games;
        } else if (!status && message) {
          Notification.show(message, {type: "error", title: "Error"});
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error", title: "Error"});
        }
      } finally {
        this.fetching = false;
      }
    },
    handleGameSelect(game) {
      if (this.selectedGame == game.encrypt_id) {
        this.selectedGame = null;
      } else {
        this.selectedGame = game.encrypt_id;
        this.$store.commit("setGame", game);
        setTimeout(() => {
          this.$router.push({path: `/${this.initial}/campaigns/select-theme`});
        }, 300);
      }
    },
  },

  beforeMount() {
    this.fetchGames();
  },
  created() {
    this.$store.commit("setGame", {});
    this.$store.commit("setTheme", {});
  }
}
</script>